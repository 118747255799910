import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getAllFruitImage } from '../../utils/FruitImgUtil.js';
import './Fruitzzle.css';

function Fruitzzle({ user, message, setMessage, loading, setLoading, logoutMain }) {
  const { t } = useTranslation();

  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);

  const [fruit, setFruit] = useState(null);
  const [position, setPosition] = useState(0);
  const [fruits, setFruits] = useState([]);
  const [name, setName] = useState('');
  const [randomName, setRandomName] = useState('');

  const [selectedLetter, setSelectedLetter] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [correctLetters, setCorrectLetters] = useState([]);
  const [wrongLetters, setWrongLetters] = useState([]);

  const [positionLetters, setPositionLetters] = useState([]);

  const [rightFruits, setRightFruits] = useState(0);
  const [time, setTime] = useState(0);

  const [blur, setBlur] = useState(10);
  const [gameTime, setGameTime] = useState(120);
  const [withAccents, setWithAccents] = useState(false);

  const startGame = () => {
    setStarted(true);
    setFinished(false);

    const theFruits = getAllFruitImage();
    const randomFruits = [...theFruits].sort(() => Math.random() - 0.5).slice(0, theFruits.length);
    setFruits(randomFruits);

    setTime(gameTime);
    setRightFruits(0);
    setPosition(0);
  }

  const startAgain = () =>  {
    setFinished(false);
    setStarted(false);
  }

  const stopGame = () => {
    setFinished(true);
    setStarted(false);
  }

  const nextFruit = () => {
    if (position !== 0) {
      setRightFruits(rightFruits + 1);
    }
    let nextPos = position + 1;
    setPosition(nextPos);
    setFruit(fruits[nextPos]);
    let theTitle = t(fruits[nextPos].title);
    let theName = null;
    if (!withAccents) {
      //theName = theTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
      theName = theTitle.replace(/[áàäâ]/g, 'a')
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .toUpperCase();
    } else {
      theName = theTitle.toUpperCase();
    }
    setName(theName);
    setRandomName(theName.split('').sort(() => Math.random() - 0.5).join(''));

    setSelectedLetter('');
    setSelectedIndex(null);
    setCorrectLetters([]);
    setWrongLetters([]);
    setPositionLetters([]);
  }


  useEffect(() => {
    let timer;
    if (started && time > 0) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

    }

    if (started && time === 0) {
      stopGame();
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [started, time]);

  useEffect(() => {
    if (fruits.length > 0) {
      nextFruit();
    }
    // eslint-disable-next-line
  }, [fruits]);

  useEffect(() => {
    setBlur(10);

    const blurInterval = setInterval(() => {
      setBlur(prevBlur => Math.max(prevBlur - 0.1, 0));
    }, 100);

    return () => clearInterval(blurInterval);
    // eslint-disable-next-line
  }, [fruit]);

  const clickRandomLetter = (index) => {

    if (correctLetters && correctLetters.length !== name.length) {
      setSelectedLetter(randomName[index]);
      setSelectedIndex(index);
    }
  }

  const clickLetter = (index) => {

    if (correctLetters && correctLetters.length !== name.length) {

      if (selectedLetter) {
        if (selectedLetter === name[index]) {
          //IF CORRECT
          let theCLetters = correctLetters.filter((c) => c !== index);
          theCLetters.push(index);
          setCorrectLetters(theCLetters);

          let theWLetters = wrongLetters.filter((c) => c !== index);
          setWrongLetters(theWLetters);

          let thePosLetters = positionLetters.filter((c) => c.index !== index);
          setPositionLetters(thePosLetters);
        } else {

          //IF WRONG
          let theCLetters = correctLetters.filter((c) => c !== index);
          setCorrectLetters(theCLetters);

          let theWLetters = [...wrongLetters];
          theWLetters.push(index);
          setWrongLetters(theWLetters);

          let thePos = positionLetters.filter((c) => c.index === index);
          let thePosLetters = [];
          if (thePos.length > 0) {
            thePosLetters = positionLetters.filter((c) => c.index !== index);
          } else {
            thePosLetters = [...positionLetters];
          }
          thePosLetters.push({ index: index, letter: selectedLetter });
          setPositionLetters(thePosLetters);

        }

        setSelectedIndex(null);
        setSelectedLetter('');
      }
    }
  }

  const handleGameTimeChange = (event) => {
    const newTime = parseInt(event.target.value);
    setGameTime(newTime);
  };

  const handleAccentsChange = (event) => {
    setWithAccents(event.target.checked);
  };

  return (
    <div className='fruitzzle'>
      {
        !started &&
        <div className='pregame'>
          <p className='bold'>{t('fruitzzle_intro')}</p>
          <div className='fruitzzle_div_input'>
            <p>{t('fruitzzle_time')}</p>
            <input type="number" value={gameTime} onChange={handleGameTimeChange} />
          </div>
          <div className='fruitzzle_div_input'>
            <p>{t('fruitzzle_accents')}</p>
            <input type="checkbox" value={withAccents} onChange={handleAccentsChange} />
          </div>
          <button onClick={() => startGame()}>{t('start')}</button>
        </div>
      }

      {started && !finished && 
        <button onClick={() => startAgain()}>{t('start_again')}</button>
      }

      {finished && !started &&
        <div className='fruitzzle_score_final_div'>
          {t('you_reached')}
          <div className='fruitzzle_score_final'>{rightFruits}</div>
        </div>
      }

      {
        started &&
        <div className='fruitzzle_gameboard'>
          <div className='fruitzzle_score_time'>
            <div className='fruitzzle_score'>
              {rightFruits}
            </div>
            <div className='fruitzzle_time'>
              {Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}
            </div>
          </div>
          <div className='fruitzzle_div_letters'>
            {
              randomName.split('').map((letter, index) => (
                <div key={index}
                  className={`fruitzzle_letter_random ${selectedIndex === index && 'fruitzzle_letter_selected'} `}
                  onClick={() => clickRandomLetter(index)}>
                  {letter}
                </div>
              ))
            }
          </div>
          {fruit &&
            <img src={fruit.img} alt={"fruit"}
              style={{
                filter: `blur(${blur}px)`, // Aplica el blur dinámicamente
                transition: "filter 0.1s", // Suaviza la transición
              }} />
          }

          <div className='fruitzzle_div_letters'>
            {
              name.split('').map((letter, index) => (
                <div key={index}
                  className={`fruitzzle_letter_name ${correctLetters.includes(index) && 'fruitzzle_letter_correct'} ${wrongLetters.includes(index) && 'fruitzzle_letter_wrong'}`}
                  onClick={() => clickLetter(index)}>
                  {correctLetters.includes(index) ? letter : (wrongLetters.includes(index) ?
                    (positionLetters && positionLetters.filter((c) => c.index === index).length > 0 && positionLetters.filter((c) => c.index === index)[0].letter)
                    : '-')}
                </div>
              ))
            }
          </div>
          {
            correctLetters && correctLetters.length === name.length &&
            <button onClick={() => nextFruit()}>{t('next')}</button>
          }
        </div>

      }
    </div>
  );
};

export default Fruitzzle;
