import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n.js';

import './AdminUsers.css';
import moment from 'moment';

function AdminUsers({ usersList }) {

  const { t } = useTranslation();

  const [showId, setShowId] = useState(false);
  const [showName, setShowName] = useState(true);
  const [showSurname, setShowSurname] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [showLanguage, setShowLanguage] = useState(false);
  const [showEnabled, setShowEnabled] = useState(true);
  const [showCreationDate, setShowCreationDate] = useState(true);
  const [showAccesses, setShowAccesses] = useState(false);
  const [showLastAccess, setShowLastAccess] = useState(true);
  const [showIsAdmin, setShowIsAdmin] = useState(false);

  function DefaultColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // undefined to remove filter
        }}
        placeholder={`${t('table_filter_find')} (${count} ${t('table_filter_records')})...`}
      />
    );
  }

  const filterByDate = (rows, id, filterValue) => {
    // Verify filter has a value
    if (!filterValue) return rows;

    return rows.filter(row => {
      // Convert date to text DD/MM/YYYY
      const rowDateText = moment(row.values.creationDate).format('DD/MM/YYYY');
      return rowDateText.includes(filterValue); // Compare if has a value in filter
    });
  };

  const columns = useMemo(() => {
    let allColumns = [
      { Header: t('user_id'), accessor: '_id', Filter: DefaultColumnFilter, show: showId },
      { Header: t('user_name'), accessor: 'name', Filter: DefaultColumnFilter, show: showName },
      { Header: t('user_surname'), accessor: 'surname', Filter: DefaultColumnFilter, show: showSurname },
      { Header: t('user_email'), accessor: 'email', Filter: DefaultColumnFilter, show: showEmail },
      { Header: t('user_language'), accessor: 'language', Filter: DefaultColumnFilter, show: showLanguage },
      {
        Header: t('user_enabled'),
        accessor: 'enabled',
        Cell: ({ value }) => (value ? 'SI' : 'NO'),
        Filter: ({ column: { setFilter } }) => (
          <select onChange={(e) => setFilter(e.target.value || undefined)}>
            <option value="">Todos</option>
            <option value="true">SI</option>
            <option value="false">NO</option>
          </select>
        ), show: showEnabled
      },
      { Header: t('user_creationDate'), accessor: 'creationDate', Cell: ({ value }) => moment(value).format('DD/MM/YYYY'), Filter: DefaultColumnFilter, filter: filterByDate, show: showCreationDate },
      { Header: t('user_accesses'), accessor: 'accesses', Cell: ({ value }) => value.length, Filter: DefaultColumnFilter, show: showAccesses },
      { Header: t('user_last_access'), id: 'lastAccess', accessor: 'accesses', Cell: ({ value }) => value.length > 0 ? moment(value[value.length - 1]).format('DD/MM/YYYY') : '', Filter: DefaultColumnFilter, show: showLastAccess },
      {
        Header: t('user_isadmin'),
        accessor: 'isAdmin',
        Cell: ({ value }) => (value ? 'SI' : 'NO'),
        Filter: ({ column: { setFilter } }) => (
          <select onChange={(e) => setFilter(e.target.value || undefined)}>
            <option value="">{t('table_filter_all')}</option>
            <option value="true">{t('table_filter_yes')}</option>
            <option value="false">{t('table_filter_no')}</option>
          </select>
        ), show: showIsAdmin
      }];

    return allColumns.filter(column => column.show);

    // eslint-disable-next-line
  }, [showId, showName, showSurname, showEmail, showLanguage, showEnabled, showCreationDate, showAccesses, showLastAccess, showIsAdmin, t]);

  const data = useMemo(() => usersList, [usersList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    // eslint-disable-next-line
  } = useTable({ columns, data }, useFilters, useSortBy);

  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  return (
    <div className='divAdminUsers'>
      <>
        <div>
          {/* Checkboxes para mostrar/ocultar columnas */}
          <label>
            <input type="checkbox" checked={showId} onChange={handleCheckboxChange(setShowId)} />
            {t('user_id')}
          </label>
          <label>
            <input type="checkbox" checked={showName} onChange={handleCheckboxChange(setShowName)} />
            {t('user_name')}
          </label>
          <label>
            <input type="checkbox" checked={showSurname} onChange={handleCheckboxChange(setShowSurname)} />
            {t('user_surname')}
          </label>
          <label>
            <input type="checkbox" checked={showEmail} onChange={handleCheckboxChange(setShowEmail)} />
            {t('user_email')}
          </label>
          <label>
            <input type="checkbox" checked={showLanguage} onChange={handleCheckboxChange(setShowLanguage)} />
            {t('user_language')}
          </label>
          <label>
            <input type="checkbox" checked={showEnabled} onChange={handleCheckboxChange(setShowEnabled)} />
            {t('user_enabled')}
          </label>
          <label>
            <input type="checkbox" checked={showCreationDate} onChange={handleCheckboxChange(setShowCreationDate)} />
            {t('user_creationDate')}
          </label>
          <label>
            <input type="checkbox" checked={showAccesses} onChange={handleCheckboxChange(setShowAccesses)} />
            {t('user_accesses')}
          </label>
          <label>
            <input type="checkbox" checked={showLastAccess} onChange={handleCheckboxChange(setShowLastAccess)} />
            {t('user_last_access')}
          </label>
          <label>
            <input type="checkbox" checked={showIsAdmin} onChange={handleCheckboxChange(setShowIsAdmin)} />
            {t('user_isadmin')}
          </label>
        </div>
        <table {...getTableProps()} style={{ width: '100%', border: '1px solid black' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {/* Heading to click to sort */}
                    <div {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {/* Sort indicator */}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </div>

                    {/* Filter */}
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>{t('table_total_rows') + ' ' + rows.length}</div>
      </>
    </div>

  );
};

export default AdminUsers;
