import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import './Signup.css';
import fruitityIcon from '../assets/fruitity-icon.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import { getAllFruitImage } from '../utils/FruitImgUtil.js';
import { getUserSignup } from '../utils/APIUtils.js';

import { auth, googleProvider } from '../firebase/firebase';
import { signInWithPopup } from 'firebase/auth';
import { getLanguages } from '../language/i18n.js';
import Selector from '../components/Selector.js';

function Signup({ loading, setLoading, message, setMessage, setClassesData }) {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [language, setLanguage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepassword, setShowRepassword] = useState(false);
  const [fruitsPositions, setFruitsPositions] = useState([]);

  const [errorsSignup, setErrorsSignup] = useState({
    errorName: false,
    errorSurname: false,
    errorEmail: false,
    errorPassword: false,
    errorRepassword: false
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { editUserAuth, loginGoogle } = useAuth();

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      let userGoogle = auth.currentUser;

      setClassesData([]);
      loginGoogle(userGoogle);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleSignup = async () => {
    setLoading(true);
    const newErrors = { ...errorsSignup };
    newErrors.errorName = false;
    newErrors.errorSurname = false;
    newErrors.errorEmail = false;
    newErrors.errorPassword = false;
    newErrors.errorRepassword = false;
    newErrors.errorLanguage = false;

    let correctSignup = true;

    if (name.length > 255) {
      newErrors.errorName = true;
      correctSignup = false;
    }

    if (surname.length > 255) {
      newErrors.errorSurname = true;
      correctSignup = false;
    }

    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regexEmail.test(email)) {
      newErrors.errorEmail = true;
      correctSignup = false;
    }

    const regexPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
    if (password.length < 8 || !regexPwd.test(password)) {
      newErrors.errorPassword = true;
      correctSignup = false;
    }

    if (password !== repassword) {
      newErrors.errorRepassword = true;
      correctSignup = false;
    }

    if (language === '') {
      newErrors.errorLanguage = true;
      correctSignup = false;
    }

    if (!correctSignup) {
      setErrorsSignup(newErrors);
    } else {
      const correctSignup = await getUserSignup(name, surname, email, password, language);
      if (correctSignup.ok) {
        setClassesData([]);
        editUserAuth(correctSignup.data);
        let lang = correctSignup.data.language ? correctSignup.data.language : 'es';
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', JSON.stringify(lang));
        navigate('/classes');
      } else {
        setMessage({ msg: t(correctSignup.error), type: 'ERR' });
      }
    }
    setLoading(false);
  };


  const clickEye = () => {
    setShowPassword(!showPassword);
  }

  const clickEyeR = () => {
    setShowRepassword(!showRepassword);
  }

  const onClickLang = (lang) => {

    i18n.changeLanguage(lang);
    localStorage.setItem('lang', JSON.stringify(lang));
    setLanguage(lang);
  }

  useEffect(() => {
    const fruits = getAllFruitImage();
    let fruitsPos = [];
    let width = window.innerWidth - 100;
    let height = window.innerHeight - 100;
    for (let fr of fruits) {
      let top = Math.floor(Math.random() * (height - 0 + 1) + 0);
      let left = Math.floor(Math.random() * (width - 0 + 1) + 0);

      fruitsPos.push({ id: fr._id, img: fr.img, top: top + 'px', left: left + 'px' });
    }
    setFruitsPositions(fruitsPos);


    let localStorageLang = localStorage.getItem('lang');

    const changeLanguage = async (language) => {
      await i18n.changeLanguage(language);
      localStorage.setItem('lang', JSON.stringify(language));
    }
    if (localStorageLang) {
      localStorageLang = JSON.parse(localStorageLang);
      changeLanguage(localStorageLang);
      onClickLang(localStorageLang);
      setLanguage(localStorageLang);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsLanguage = getLanguages();


  return (
    <div className='signupDivOutside'>
      <div className='signupDivBackground'>
        <div className='signupDivBackgroundOutside'>
          {fruitsPositions.map((fr) => (
            <img alt="frpos" width={'100px'} height={'100px'} src={fr.img} key={fr.id} style={{ top: fr.top, left: fr.left }} />
          ))}
        </div>
      </div>
      <div className='signupDivInside'>
        <div className='signupDivImg'>
          <img alt="fruitityIcon" className="signupImg" src={fruitityIcon} />
          <h1 className='menuTitle'>Fruitity</h1>
        </div>
        <div className='signupDivForm'>
          <h1 className='signupTitle'>{t('signup')}</h1>
          <div className="signupForm">
            <div className='signupInput'>
              <label htmlFor="name">{t('name')}</label>
              <input type="text" id="name" name="name" value={name} placeholder={t('name')} onChange={(e) => setName(e.target.value)} />
              {errorsSignup && errorsSignup.errorName && <p className='signupError pError'>{t('wrongSignupName')}</p>}
            </div>

            <div className='signupInput'>
              <label htmlFor="surname">{t('surname')}</label>
              <input type="text" id="surname" name="surname" value={surname} placeholder={t('surname')} onChange={(e) => setSurname(e.target.value)} />
              {errorsSignup && errorsSignup.errorSurname && <p className='signupError pError'>{t('wrongSignupSurname')}</p>}
            </div>

            <div className='signupInput'>
              <label htmlFor="email">{t('email')}</label>
              <input type="text" id="email" name="email" value={email} placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} />
              {errorsSignup && errorsSignup.errorEmail && <p className='signupError pError'>{t('wrongSignupEmail')}</p>}
            </div>

            <div className='signupInput'>
              <label htmlFor="password">{t('password')}</label>
              <div className='signupDivPassword'>
                <input type={showPassword ? 'text' : 'password'} id="password" name="password" value={password} placeholder={t('password')} onChange={(e) => setPassword(e.target.value)} />
                <FontAwesomeIcon className="signupEyeIcon" icon={showPassword ? faEye : faEyeSlash} onClick={clickEye} />
              </div>
              {errorsSignup && errorsSignup.errorPassword && <p className='signupError pError'>{t('wrongSignupPassword')}</p>}
            </div>


            <div className='signupInput'>
              <label htmlFor="repassword">{t('repassword')}</label>
              <div className='signupDivPassword'>
                <input type={showRepassword ? 'text' : 'password'} id="repassword" name="repassword" value={repassword} placeholder={t('repassword')} onChange={(e) => setRepassword(e.target.value)} />
                <FontAwesomeIcon className="signupEyeIcon" icon={showRepassword ? faEye : faEyeSlash} onClick={clickEyeR} />

              </div>
              {errorsSignup && errorsSignup.errorRepassword && <p className='signupError pError'>{t('wrongSignupRepassword')}</p>}
            </div>

            <div className='signupInput'>
              <label htmlFor="">{t('select_language')}:</label>
              <Selector
                options={optionsLanguage}
                selectedOption={language}
                onOptionChange={onClickLang} />
            </div>


            <button onClick={handleSignup}>{t('signup')}</button>
            <button className='btnSignupGoogle' onClick={signInWithGoogle}>{t('signup-with')} <FontAwesomeIcon className='iconSignupGoogle' icon={faGoogle} /></button>

            <Link to="/login">
              <p className='signupLink'>{t('alredyAccountGoToLogin')}</p>
            </Link>
          </div>
        </div>
        {/* {errorsSignup &&
          <p className="signupError">{errorsSignup}</p>} */}
      </div>
    </div>
  );
};

export default Signup;
