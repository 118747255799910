import React, { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getAdminClasses, getAdminNews, getAdminUsers } from '../../utils/APIUtils.js';

import './Admin.css';
import AdminUsers from './users/AdminUsers.js';
import AdminClasses from './classes/AdminClasses.js';
import AdminNews from './news/AdminNews.js';

function Admin({ user, message, setMessage, loading, setLoading, logoutMain }) {

  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const [usersList, setUsersList] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [newsList, setNewsList] = useState([]);


  const fetchUsersData = async () => {
    setLoading(true);
    const theData = await getAdminUsers(user.token);

    if (theData.ok) {

      setUsersList(theData.data);

    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }

  const fetchClassesData = async () => {
    setLoading(true);
    const theData = await getAdminClasses(user.token);

    if (theData.ok) {

      setClassesList(theData.data);

    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }

  const fetchNewsData = async () => {
    setLoading(true);
    const theData = await getAdminNews(user.token);

    if (theData.ok) {

      setNewsList(theData.data);

    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }


  useEffect(() => {
    console.log('fetchdata')
    fetchUsersData();
    fetchClassesData();
    fetchNewsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='divAdmin'>

     <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <TabList className={'divAdminOptions'}>
        <Tab>{t('users')}</Tab>
        <Tab>{t('classes')}</Tab>
        <Tab>{t('news')}</Tab>
      </TabList>

      <TabPanel>
        <AdminUsers usersList={usersList} />
      </TabPanel>
      <TabPanel>
        <AdminClasses usersList={usersList} classesList={classesList}/>
      </TabPanel>
      <TabPanel>
        <AdminNews 
        user={user}
        message={message}
        setMessage={setMessage}
        loading={loading}
        setLoading={setLoading}
        logoutMain={logoutMain}
        newsList={newsList}
        setNewsList={setNewsList}
        />
      </TabPanel>
    </Tabs>
    </div>

  );
};

export default Admin;
